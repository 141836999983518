.btn {
  border: 2px solid;
  @apply inline-block bg-accent px-8 py-4 leading-none text-black font-medium rounded-md text-base transition-all duration-200 ease-in-out tracking-tight w-full text-center border-accent;

  @screen md {
    @apply w-auto;
  }

  &:hover {
    @apply bg-black text-white border-black;
  }

  &.hover\:btn-white:hover {
    @apply bg-white text-black border-white;
  }

  &.btn--small {
    @apply py-3 px-5;
  }

  &.btn-white {
    @apply bg-white text-black;

    &:hover {
      @apply opacity-75;
    }
  }

  &.btn-blue {
    @apply bg-dark-blue text-white border-dark-blue;

    &:hover {
      @apply bg-accent text-black border-accent;
    }
  }

  &.btn-outline-white {
    @apply text-white bg-transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  &.btn-outline-black {
    @apply text-black bg-transparent cursor-pointer transition-colors duration-150 ease-in-out;

    &--white-bg {
      @apply bg-white;
    }

    border: 2px solid rgba(4, 27, 41, 0.1);
    &.border-1px {
      border-width: 1px;
      padding-top: calc(1rem + 1px);
      padding-bottom: calc(1rem + 1px);
    }
    &:hover {
      @apply border-black;
    }
    &.btn-outline-black-hover-light:hover {
      border-color: rgba(4, 27, 41, 0.3);
    }
  }

  &.btn-outline-yellow {
    @apply text-black bg-transparent cursor-pointer transition-colors duration-150 ease-in-out;
    border: 2px solid #f3b545;
    &:hover {
      @apply border-black;
    }
  }

  &.outline-button {
    @apply text-white bg-transparent;
    border: 2px solid #f3b545;

    &:hover {
      @apply border-white;
    }

    &.text-black {
      color: var(--black);
      &:hover {
        // @apply text-white;
        border-color: #f3b545;
        background-color: #f3b545;
      }
    }
  }

  &.btn-grey {
    background-color: rgba(4, 27, 41, 0.1);
    @apply text-black;
    border: 2px solid rgba(4, 27, 41, 0);

    &:hover {
      @apply border-black;
    }
  }

  &.outline-button--grey {
    border: 1px solid rgba(255, 255, 255, 0.4);
    &:hover {
      background:none!important;
      border-color: white!important;
    }
  }
}

.btn-underline {
  transition: border .15s, color .15s;
  @apply inline-block font-semibold pb-1;
  border-bottom: 2px solid #D1CFCC;
  &:hover {
    border-bottom-color: darken(#D1CFCC, 10%);
  }

  &--white,
  &--white-hover:hover {
    border-bottom-color: var(--white);
  }

  &--dark-blue,
  &--dark-blue-hover:hover {
    border-bottom-color: theme('colors.dark-blue');
  }
}

.group:hover .btn-underline--dark-blue-hover {
  border-bottom-color: theme('colors.dark-blue');
}


.bg-dark-blue.text-white {
  .outline-button {
    color:white;
  }
}

.in-view-check {
  transform: translateY(10%);
  opacity: 0;
  transition: all 0.4s ease-in-out !important;

  &.is-in-view {
    opacity: 1;
    transition-delay: 0.3s;
    transform: none;
  }
}

.text-link {
  border-bottom: 5px solid;
  @apply font-medium border-grey pb-1 inline-block transition-colors duration-150 ease-in-out;
  &.text-link--yellow {
    @apply border-yellow;
  }
  &.text-link--arrow {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      right: -40%;
      top: 23%;
      height: 30px;
      width: 30px;
      background-image: url('/images/arrow-top-right-black.svg');
      background-repeat: no-repeat;
    }
  }

  &.text-link--white {
    border-color: rgba(255, 255, 255, 0.3);

    &:not(.no-hover):hover,
    .text-link-hover-target:hover & {
      @apply border-white;
    }
  }
  &:not(.no-hover):hover,
  .text-link-hover-target:hover & {
    @apply border-black;
  }
}

.pricing {
  .text-link {
    display: block;
    margin: auto;
    width: fit-content;
    @media screen and (min-width: 768px) {
      display: inline-block;
      width: auto;
    }
  }
}

.icon__link {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    right: -21%;
    top: 35%;
    height: 30px;
    width: 30px;
    background-image: url('/images/arrow-top-right.svg');
    background-repeat: no-repeat;
  }
}
