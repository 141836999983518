/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type=range].range-slider {
  height: 2.2em;
  -webkit-appearance: none;
}

input[type=range] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*progress support*/
input[type=range].range-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.25rem + var(--ratio) * (100% - 1.25rem));
}

input[type=range].range-slider:focus {
  outline: none;
}

/*webkit*/
input[type=range].range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1em;
  background: #F3B545;
  border: none;
  box-shadow: none;
  margin-top: calc(4px * 0.5 - 1.25rem * 0.5);
}

input[type=range].range-slider::-webkit-slider-runnable-track {
  height: 4px;
  border: none;
  background: #E6E8EB;
  box-shadow: none;
}

input[type=range].range-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#F3B545,#F3B545) 0/var(--sx) 100% no-repeat, #E6E8EB;
}

/*mozilla*/
input[type=range].range-slider::-moz-range-thumb {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1em;
  background: #F3B545;
  border: none;
  box-shadow: none;
}

input[type=range].range-slider::-moz-range-track {
  height: 4px;
  border: none;
  background: #E6E8EB;
  box-shadow: none;
}

input[type=range].range-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#F3B545,#F3B545) 0/var(--sx) 100% no-repeat, #E6E8EB;
}

/*ms*/
input[type=range].range-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].range-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].range-slider::-ms-thumb {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1em;
  background: #F3B545;
  border: none;
  box-shadow: none;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].range-slider::-ms-track {
  height: 4px;
  border-radius: 0.5em;
  background: #E6E8EB;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].range-slider.slider-progress::-ms-fill-lower {
  height: 4px;
  margin: -undefined 0 -undefined -undefined;
  background: #F3B545;
  border: none;
  border-right-width: 0;
}
.range-label {
  min-width: 17%;
  max-width: 17%;
  font-size: 14px;
  text-wrap: nowrap;

  @screen lg {
    font-size: 16px;
  }
  @screen xl {
    min-width: 10%;
    max-width: 10%;
  }
}
