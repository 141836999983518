$font-path: '/fonts';
$xxs: rem(400px);
$xs: rem(480px);
$sm: rem(640px);
$md: rem(768px);
$lg: rem(1024px);
$xl: rem(1280px);
$xxl: rem(1440px);
$responsive_size_modifier: 0.825;
$responsive_size_modifier_large: 0.5;

// Font definitions
@font-face {
  font-family: 'Open Sauce Sans';
  src:
    url('#{$font-path}/OpenSauceSans-Medium.woff2') format('woff2'),
    url('#{$font-path}/OpenSauceSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src:
    url('#{$font-path}/OpenSauceSans-Regular.woff2') format('woff2'),
    url('#{$font-path}/OpenSauceSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src:
    url('#{$font-path}/OpenSauceSans-SemiBold.woff2') format('woff2'),
    url('#{$font-path}/OpenSauceSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Font sizing
.u-h1 {
  font-size: 2.375rem; // 38px
  line-height: 1.2;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen md {
    font-size: 2.625rem;
  }
  @screen lg {
    font-size: 3.25rem; // 52px
  }
}

.u-52px {
  font-size: 2.375rem;
  line-height: 1.2;
  letter-spacing: -0.033em;
  @apply font-semibold;
  @screen md {
    font-size: 2.625rem;
  }
  @screen lg {
    font-size: 52px;
  }
}

.u-50px {
  font-size: 2.375rem;
  line-height: 1.2;
  letter-spacing: -0.033em;

  @screen lg {
    font-size: 3.125rem;
    line-height: 1.15;
  }
}

.u-h2 {
  font-size: 36px;
  line-height: 1.1;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen md {
    font-size: 36px;
  }
  @screen xl {
    font-size: 50px;
  }
}

.text-43px {
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen md {
    font-size: 36px;
  }
  @screen xl {
    font-size: 43px;
  }
}

.u-h3 {
  font-size: 24px;
  line-height: 1.1;
  letter-spacing: -0.033em;
  @apply font-semibold;

  @screen lg {
    font-size: 28px;
  }
}

.u-h4 {
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  @apply font-bold;

  @screen md {
    font-size: 36px;
  }
}

.u-h5 {
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  @apply font-bold;

  @screen md {
    font-size: 28px;
  }
}

.u-p1 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.005em;

  @screen lg {
    font-size: 20px;
    line-height: 32px;
  }
}

.u-p2 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.005em;

  @screen lg {
    font-size: 18px;
    line-height: 32px;
  }
}

.u-p3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.005em;

  @screen lg {
    font-size: 16px;
    line-height: 24px;
  }
}

.u-s1 {
  font-size: 16px;
  @apply text-accent font-medium;
  @screen md {
    font-size: 18px;
  }

  section.bg-yellow & {
    @apply text-black;
  }
}

.u-e1 {
  font-size: 16px;
  @apply text-red font-medium;
  @screen md {
    font-size: 18px;
  }
}

@responsive {
  .text-48px {
    font-size: 48px;
  }

  .text-32px {
    font-size: 32px;
  }

  .text-28px {
    font-size: 28px;
  }

  .text-22px {
    font-size: 22px;
  }

  .text-21px {
    font-size: 21px;
  }
  .text-20px {
    font-size: 20px;
  }

  .text-18px {
    font-size: 18px;
  }

  .text-24px {
    font-size: 24px;
  }

  .text-17px {
    font-size: 17px;
  }

  .text-16px {
    font-size: 16px;
  }

  .text-15px {
    font-size: 15px;
  }

  .text-14px {
    font-size: 14px;
  }

  .text-13px {
    font-size: 13px;
  }

  .text-12px {
    font-size: 12px;
  }
  .text-11px {
    font-size: 11px;
  }
}

.prose li:before {
  display: none !important;
}

.prose-fix-bullets {
  ul li {
    &:before {
      display: none;
    }
  }
}

// New font sizing
.text-52 {
  @include clamp('font-size', rem(60px * $responsive_size_modifier_large), rem(52px), $md, $xl);
  line-height: 1.3;
  letter-spacing: -0.04em;
}

.text-50 {
  @include clamp('font-size', rem(60px * $responsive_size_modifier_large), rem(50px), $md, $xl);
  line-height: 1.3;
  letter-spacing: -0.02em;
}

.text-44 {
  @include clamp('font-size', rem(52px * $responsive_size_modifier_large), rem(44px), $md, $xl);
  line-height: 1.3;
  letter-spacing: -0.02em;
}

.text-36 {
  @include clamp('font-size', rem(48px * $responsive_size_modifier_large), rem(36px), $md, $xl);
  line-height: 1.4;
  letter-spacing: -0.02em;
}

.text-28 {
  @include clamp('font-size', rem(36px * $responsive_size_modifier_large), rem(28px), $md, $xl);
  line-height: 1.4;
  letter-spacing: -0.02em;
}

.text-20 {
  @include clamp('font-size', rem(36px * $responsive_size_modifier_large), rem(20px), $md, $xl);
  line-height: 1.6;
  letter-spacing: -0.02em;
}

.text-18 {
  @include clamp('font-size', rem(24px * $responsive_size_modifier_large), rem(18px), $md, $xl);
  line-height: 1.6;
  letter-spacing: -0.01em;
}

.text-16 {
  @include clamp('font-size', rem(24px * $responsive_size_modifier_large), rem(16px), $md, $xl);
  line-height: 1.6;
  letter-spacing: -0.01em;
}

// Prose
.prose-styling {
  ul {
    list-style-type: disc;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    margin-left: 1.3333em;

    li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      padding-left: 0;
    }
  }

  p {
    margin-bottom: 1.25em;
  }
}
