@import '~tailwindcss/base';
:root {
  --accent: #f3b545;
  --black: #041b29;
  --red: #991b1e;
  --tertiary: #f6f3f0;
}
@import '~tailwindcss/components';

// CUSTOM STYLES HERE
@import 'settings/_settings.mixins.scss';
@import 'settings/_settings.fonts.scss';
@import 'elements/_elements.header.scss';
@import 'settings/_settings.helpers.scss';
@import 'elements/_elements.forms.scss';
@import 'elements/_elements.buttons.scss';
@import 'elements/_elements.range.scss';
@import 'elements/_elements.videos.scss';
@import 'elements/_elements.post.scss';

@import '~tailwindcss/utilities';
