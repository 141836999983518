body.nav-open {
  overflow: hidden;
  width: 100%;

  @screen md {
    overflow: initial;
    height: auto;
    width: 100%;
  }

  .header {
    @apply bg-white text-black;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  }
}

.nav-open {
  .mobile-nav {
    transform: translateY(0);

    @screen md {
      transform: translateY(-100%);
    }
  }
}

.header {
  &.is-scrolled {
    @apply bg-white text-black;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.05);

    li a {
      @apply text-black;
    }
  }

  li.has-sub {
    &:hover {
      .header__subnav {
        display: flex;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.05);

        &__cont {
          opacity: 1;
        }
      }
    }
  }
}

.tg-hamburger {
  body.nav-open & {
    div {
      span {
        &:first-child {
          transform: rotate(-45deg);
          top: 9px;
        }

        &:last-child {
          transform: rotate(45deg);
          bottom: 9px;
        }
      }
    }
  }

  div {
    width: 23px;
    height: 20px;
  }

  span {
    @apply transition-all duration-200 ease-in-out;
    height: 2px;
    width: 25px;
    content: "";

    &:first-child {
      top: 5px;
    }

    &:last-child {
      bottom: 5px;
    }
  }
}
