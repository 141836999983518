.video-collection{
  opacity: 0.6;
  &.active {
    opacity: 1;
    &:before {
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      height: .25rem;
      background-color: var(--accent);
    }
  }
}