html {
  scroll-padding-top: 100px;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
}


#ebook-form {
  background-color: #fff;
  padding: 2rem;
  padding-bottom: 1rem;
  border-radius: 0.5em;
}

.pricing-buttons .buttons {
  width: 100%;
  .pl-6:first-of-type .btn {
    min-width: 222px;
  }
}

.\!flex-\[1_1_25\%\] {
  flex: 1 1 25% !important;
}

.\!w-auto {
  width: auto !important;
}

// .integrations-preview-item {
//   .integrations-preview-item__button {
//     transition: border-color .15s, color .15s;
//   }
//   &:hover {
//     .integrations-preview-item__button {
//       border-color: darken(#D1CFCC, 10%)!important;
//     }
//   }
// }

// .integrations-cta {
//   .integrations-cta__button {
//     transition: border-color .15s, color .15s;
//   }

//   &:hover {
//     .integrations-cta__button {
//       border-color: #041b29!important;
//     }
//   }
// }

.subnav-item {
  .subnav-item-icon {
    transition: background-color 0.2s;
    img {
      // transition:filter .2s;
    }
  }
  &:hover {
    .subnav-item-icon {
      background-color: #1a1a1a;
      img {
        filter: brightness(500);
      }
    }
  }
}

.\!flex-\[0_0_140px\] {
  flex: 0 0 140px !important;
}

// .pricing-buttons {
//   .buttons,
//   .btn,
//   .text-link {
//     width:100%!important;
//     text-align: center;
//   }
//   .sm\:w-auto {
//     width:50%;
//   }
// }

.\!items-start {
  align-items: flex-start !important;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.container-padding {
  @apply px-6 lg:px-10 mx-auto w-full;
  max-width: 1280px;
}

.no-container {
  .container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 0 0 0 !important;
  }
}

.\!bg-\[\#f7f4f1\] {
  background-color: #f7f4f1 !important;
}

[data-aos^='wipe'][data-aos^='wipe'] {
  &.aos-animate {
    &:after {
      @apply w-full;
    }
  }
}

.remove-container .container {
  max-width: 100%;
  padding: 0 0 0 0;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 500ms ease-in-out;
}

@keyframes fadeIn80 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
.fadeIn80 {
  animation: fadeIn80 600ms ease-in-out;
}

[data-aos^='InUp'][data-aos^='InUp'] {
  opacity: 0;
  transform: translate3d(0, 40px, 0);

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

.img-invert-white {
  img {
    filter: brightness(0) invert(1);
  }
}

.img-invert-black {
  img {
    filter: brightness(0);
  }
}

.img-object-contain {
  img {
    @apply object-contain;
  }
}

.img-object-cover {
  img {
    @apply object-cover;
  }
}

.img-object-bottom {
  img {
    @apply object-bottom;
  }
}

.img-h-full {
  img {
    @apply h-full w-auto;
  }
}

.\!next-img-h-full {
  > span {
    height: 100% !important;
  }
}

.img-w-full {
  img {
    @apply w-full;
  }
}

.img-full {
  img {
    @apply h-full w-full;
  }
}

.img-mx-auto {
  img {
    @apply mx-auto;
  }
}
.md\:max-w-\[75\%\] {
  @screen md {
    max-width: 75%;
  }
}

.imageContainer {
  width: 100%;

  div {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

.sticky-sidebar {
  @screen md {
    @apply sticky;
    top: 120px;
  }
}

.prose-white a {
  color: #fff;
}

.prose,
.prose-lg {
  ul,
  ol {
    margin-left: 1.3333em;
    li {
      padding-left: 0;
    }
    p {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
    li {
      padding-left: 0.5em;
    }
  }
  a {
    color: var(--accent);
    text-decoration: underline;
  }
}

.trans {
  @apply transition-default duration-150 ease-in-out;
}

.b-light {
  border-color: rgba(0, 0, 0, 0.1);
}

.rounded-edge-right {
  border-top-right-radius: 11em;
  border-bottom-right-radius: 11em;
  overflow: hidden;

  video {
    transform: scale(1.01);
  }
}

.highlight {
  @apply inline-block relative z-10;

  &:before {
    @apply absolute bg-center bg-no-repeat;
    z-index: -1;
    content: '';
    width: 105%;
    height: 110%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url('/images/swoosh.png');
    background-size: 100% 100%;
  }

  &-white:before {
    background-image: url('/images/swoosh-white.svg');
    left: 52%;
    top: 53%;
  }
}

.circle-ring {
  @apply relative z-10 outline-none;
  box-shadow: none !important;

  &:before {
    @apply absolute bg-center bg-contain bg-no-repeat outline-none;
    z-index: -1;
    content: '';
    width: 105%;
    height: 110%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url('/images/circle.png');
    background-size: 100% 100%;
  }
}

@responsive {
  .underswipe {
    @apply relative z-10;

    &:after {
      @apply absolute bg-center bg-contain bg-no-repeat;
      z-index: -1;
      content: '';
      width: 105%;
      height: 35%;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      background-image: url('/images/underline.png');
    }
  }
}

.key-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;

  @screen md {
    grid-template-columns: 1fr 0.25fr 1fr 0.25fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 2em;
    grid-template-areas: '. . . . .';
  }

  .hor {
    height: 1px;
    @apply w-full;
    border-top: 1px dashed #041b29;
    border-color: rgba(0, 0, 0, 0.1);

    @screen md {
      width: 1px;
      @apply h-full;
      border-top: 0;
      border-left: 1px dashed #041b29;
      border-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.stats-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;

  @screen md {
    grid-template-columns: 1fr 0.25fr 1fr 0.25fr 1fr 0.25fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 2em;
    grid-template-areas: '. . . . .';
  }

  .hor {
    height: 1px;
    @apply w-full;
    border-top: 1px dashed rgba(255, 255, 255, 0.1);

    @screen md {
      width: 1px;
      @apply h-full;
      border-top: 0;
      border-left: 1px dashed rgba(255, 255, 255, 0.1);
    }
  }
}

.learn-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;

  @screen md {
    display: grid;
    grid-template-columns: 1fr 0.25fr 1fr;
    grid-template-rows: 1fr;
    gap: 24px 0px;
    grid-template-areas: '. . .';
  }

  .hor {
    height: 1px;
    @apply w-full;
    border-top: 1px dashed rgba(0, 0, 0, 0.1);

    @screen md {
      width: 1px;
      @apply h-full;
      border-top: 0;
      border-left: 1px dashed rgba(0, 0, 0, 0.1);
    }
  }
}

.border-t {
  border-top-width: 1px;
}

.press-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;

  @screen md {
    display: grid;
    grid-template-columns: 1fr 0.25fr 1fr;
    grid-template-rows: 1fr;
    gap: 24px 0px;
    grid-template-areas: '. . .';
  }

  .hor {
    height: 1px;
    @apply w-full;
    border-top: 1px dashed #041b29;

    @screen md {
      width: 1px;
      @apply h-full;
      border-top: 0;
      border-left: 1px dashed #041b29;
    }
  }
}
.pagination {
  .page-numbers {
    @apply px-4 transition-all duration-150 ease-in-out;

    &:hover {
      @apply text-accent;
    }

    &.current {
      @apply text-accent;
    }

    &.next,
    &.prev {
      @apply px-0;

      &:hover {
        @apply opacity-50;
      }
    }
  }
}

.contact__title {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    right: -36%;
    top: 49%;
    height: 30px;
    width: 30px;
    background-image: url('/images/arrow-bottom-right-white.svg');
    background-repeat: no-repeat;
  }
}

.legal-copy {
  a {
    @apply text-link;
  }
}

blockquote {
  font-size: 1.75rem;
}

.intro-company-logo {
  width: 100%;
  max-width: 182px;

  &.intro-company-logo--with-padding {
    max-width: calc(182px + 0.75rem);
  }
}

.gallery-modal {
  .modal-video {
    .modal-video-inner {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
    .modal-video-movie-wrap {
      background-color: transparent;
      padding-bottom: 0 !important;
      height: auto;
      max-height: 100vh;
      max-width: 75vw;
      width: auto;
      video {
        max-height: 80vh;
        width: auto;
        margin: auto;
      }
    }
  }
}

.vidyard-modal {
  .modal-video-body {
    max-width: 470px;
  }
}

.vidyard-preview-image {
  width: 80px;
  @media screen and (min-width: 472px) {
    aspect-ratio: 119 / 74;
    width: 153px;
  }
}

.greenhouse-job-description {
  ul,
  li {
    margin-left: 1.5rem;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
}

.dash-border-box div {
  @screen lg {
    &:not(:first-child) {
      border-left: 1px dashed rgba(#041b29, 0.25);
    }
  }
}
.border-y-1px {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.gap-1px {
  gap: 1px;
}

.circle-ring {
  &-top,
  &-bottom {
    @apply relative z-10 py-6;
    &:after {
      @apply absolute bg-center bg-contain bg-no-repeat;
      z-index: -1;
      content: '';
      width: 100%;
      height: 100%;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
    }
  }

  &-stretch {
    &:after {
      background-size: 100% 100%;
    }

    &.circle-ring-top {
      &:after {
        bottom: 0.5rem;
      }
    }
    &.circle-ring-bottom {
      &:after {
        top: 0.5rem;
      }
    }
  }
  &-top {
    &:after {
      background-image: url('/images/circle-ring-top.png');
    }
  }
  &-bottom {
    &:after {
      background-image: url('/images/circle-ring-bottom.png');
    }
  }
}

.\!h-auto {
  height: auto !important;
}

.arrow-animation-wrapper {
  position: absolute;
  inset: 0 auto 0 100%;
  width: 11rem;
  display: flex;
  justify-content: center;
  // animation: appear 4s linear forwards;

  .arrow-animation {
    height: 1rem;
    align-self: center;
    position: relative;

    .arrow {
      width: 1rem;
      height: 1rem;
      border: 0.25rem solid;
      border-color: var(--accent) transparent transparent var(--accent);
      transform: rotate(-45deg);
    }

    .arrow-sliding {
      position: absolute;
      animation: slide 4s linear infinite;

      &.delay1 {
        opacity: 0;
        animation-delay: 1s;
      }
      &.delay2 {
        opacity: 0;
        animation-delay: 2s;
      }
      &.delay3 {
        opacity: 0;
        animation-delay: 3s;
      }
    }

    @keyframes slide {
      0% {
        opacity: 0;
        transform: translateX(0);
      }
      50% {
        opacity: 1;
        transform: translateX(-2.5rem);
      }
      100% {
        opacity: 0;
        transform: translateX(-5rem);
      }
    }

    @keyframes appear {
      0% {
        opacity: 0;
      }
      90% {
        opacity: 0;
      }
      95% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.hover\:bg-light-grey:hover {
  background-color: #e6e8eb;
}

.two-col-stacking-animation {
  > *:not(:first-child) {
    > .relative {
      &:before {
        content: '';
        @apply absolute inset-x-0 top-auto;
        bottom: 100%;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(4, 27, 41, 1) 0%, rgba(4, 27, 41, 0) 35%, rgba(4, 27, 41, 0) 100%);
      }
    }
  }
}

.calc-remove-min-width {
  .items-center {
    align-items: start;
  }
  .lg\:min-w-2xl {
    min-width: 0 !important;
    width: 100%;
  }
  .calculator {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.word-rotator-hide-original {
  .text-accent {
    opacity: 0;
  }
}
.word-rotator {
  position: relative;
  overflow: hidden;

  .swiper-container {
    height: 100%;
  }

  .word-rotator__long {
    opacity: 0;
    margin-bottom: 5px;
  }

  .word-rotator__cont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

.table-of-contents {
  counter-reset: list-number;
  @apply flex flex-col gap-2;
  ol {
    @apply flex gap-6;
    &:before {
      counter-increment: list-number;
      content: counter(list-number) '. ';
      font-size: 14px;
      color: inherit;
    }
  }
}

.absolute-ribbon {
  width: 39%;

  top: -1rem;
  right: -0.9rem;

  @media screen and (min-width: 410px) {
    width: 33%;
  }

  @media screen and (min-width: 640px) {
    top: -0.7rem;
    right: -0.65rem;
  }
  @media screen and (min-width: 1280px) {
    top: -0.8rem;
    right: -0.7rem;
  }
}
// .ribbon {
//   --d: 6px; /* folded part */
//   --c: #F3B545; /* color */
//   --f: 7px; /* ribbon font-size */

//   width: 20%;
//   height: 20%;
//   position: relative;
//   display: inline-block;
//   margin: 0;
//   background: lightblue;
// }

// .ribbon::before {
//   content: attr(data-ribbon);
//   position: absolute;
//   font-size: var(--f);
//   top: 0;
//   right: 0;
//   transform: translate(29.29%, -100%) rotate(45deg);
//   color: #fff;
//   text-align: center;
//   border: 1px solid transparent;
//   border-bottom: 0;
//   transform-origin: bottom left;
//   padding: 5px 35px calc(var(--d) + 5px);
//   background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
//     no-repeat var(--c);
//   background-clip: padding-box;
//   clip-path: polygon(0 0,100% 0,100% 100%,calc(100% - var(--d)) calc(100% - var(--d)),var(--d) calc(100% - var(--d)),0 100%);
//   -webkit-mask: linear-gradient(135deg,transparent calc(50% - var(--d) * 0.707),#fff 0) bottom left,
//     linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)bottom right;
//   -webkit-mask-size: 300vmax 300vmax;
//   -webkit-mask-composite: destination-in;
//   mask-composite: intersect;
// }

// .ribbon-left::before {
//   left: 0;
//   right: auto;
//   transform: translate(-29.29%, -100%) rotate(-45deg);
//   transform-origin: bottom right;
// }

.grid.grid-col-3-center-align {
  @screen md {
    @apply flex flex-row justify-center;
    .flex.flex-col {
      max-width: 390px;
    }
  }
}
